
import Cookies from "js-cookie"

export function setCookie(name: string, value: Object | string, options: Object = {}) {
	Cookies.set(name, value, options);
}

export function getCookie(name: string, json: boolean = true) {
	if (json) {
		return Cookies.getJSON(name);
	} else {
		return Cookies.get(name);
	}
}

export function removeCookie(name: string) {
	Cookies.remove(name);
}