
import { send, ApiResult } from "../lib/send"
import { apiBaseUrl } from "../environment"

const boardApiPath = `${apiBaseUrl}/board`;
const boardApiRoutes = {
	boardstate_get: { url: boardApiPath, method: "post", func: 'boardstate_get' },
	boardstate_put: { url: boardApiPath, method: "post", func: 'boardstate_put' },
	pdf_get: { url: boardApiPath, method: "post", func: 'pdf_get' },
	pdf_put: { url: boardApiPath, method: "post", func: 'pdf_put' },
};

type GetUrlInfo = {
	getUrl: string,
	expiresMillis: number,
}

type PutUrlInfo = {
	putUrl: string,
	expiresMillis: number,
}

type PdfPutInfo = {
	putUrl: string,
	getUrl: string,
	urlId: string,
	expiresMillis: number,
}

export async function get_boardstate_get_info(boardToken: string): Promise<ApiResult<GetUrlInfo>> {
	if (!boardToken || boardToken.length === 0) return { success: false, reason: "invalid token" };
	return await send({ boardToken }, boardApiRoutes.boardstate_get);
}

export async function get_boardstate_put_info(boardToken: string): Promise<ApiResult<PutUrlInfo>> {
	if (!boardToken || boardToken.length === 0) return { success: false, reason: "invalid token" };
	return await send({ boardToken }, boardApiRoutes.boardstate_put);
}

export async function get_pdf_get_info(boardToken: string, pdfUrlId: string): Promise<ApiResult<GetUrlInfo>> {
	if (!boardToken || boardToken.length === 0) return { success: false, reason: "invalid token" };
	return await send({ boardToken, pdfUrlId }, boardApiRoutes.pdf_get);
}

export async function get_pdf_put_info(boardToken: string): Promise<ApiResult<PdfPutInfo>> {
	if (!boardToken || boardToken.length === 0) return { success: false, reason: "invalid token" };
	return await send({ boardToken }, boardApiRoutes.pdf_put);
}