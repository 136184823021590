
import fetch from "node-fetch"

// should be called ApiResponse
export type ApiResult<T> = 
| { success: true, result: T }
| { success: false, reason: string }

function isApiResult<T>(x: any): x is ApiResult<T> {
	if (!x) return false;
	if (!("success" in x)) return false;
	// TODO test additional criteria
	return true;
}

export async function send(params, {url, method, func}): Promise<ApiResult<any>> {
	try {
		const response = await fetch(
			url,
			{
				method,
				headers: new Headers({
					"Content-Type": "application/json",
					Accept: "application/json",
				}),
				// credentials: 'include', // if set to 'include', then cors cannot use wildcard (this is probably only necessary if using cookies)
				body: JSON.stringify({func, params}),
			}
		);
		let result = await response.json();
		if (!isApiResult(result)) {
			return { success: false, reason: "unexpected response from server" };
		}
		return result;
	} catch (exception) {
		console.log(exception);
		return { success: false, reason: exception };
	}
}

export async function putFile(file: File|string, url): Promise<boolean> {
	try {
		await fetch(
			url,
			{
				method: "PUT",
				headers: {
					"Content-Type": "binary/octet-stream",
				},
				body: file,
			}
		);
		return true;
	} catch (exception) {
		return false;
	}
}

export async function getFile(url: string): Promise<string> {
	try {
		const response = await fetch(
			url,
			{
				method: "GET",
			}
		);
		return await (await response.blob()).text();
	} catch (error) {
		console.error(error);
		return "";
	}
}
