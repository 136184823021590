
import { getCookie, setCookie, removeCookie } from "./cookie"
import User from "./user"

class Session {

	public user: User | null = null;

	constructor() {
		const userTokenOrNull = getCookie("user");
		if (userTokenOrNull) {
			this.user = new User(userTokenOrNull);
		}
	}

	// TODO this should be private
	public newUserToken(token: string, stay_logged_in: boolean = false) {
		if (this.user) {
			this.user.updateToken(token);
		} else {
			this.user = new User(token);
		}
		if (stay_logged_in) {
			setCookie("user", token, { expires: 31 }); // cookie will last for 31 days
		} else {
			setCookie("user", token); // with no expiration, cookie will last until browser is closed
		}
	}

	public logout() {
		this.user = null;
		removeCookie("user");
	}

}

export default new Session();