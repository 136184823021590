
/*
	update pricing and old_pricing_individual to use routes
*/

import { stagingPrefix } from "./environment"

const staging = {
	account: {
		boards: `${stagingPrefix}/account/boards`,
		contacts: `${stagingPrefix}/account/contacts`,
		feedback: `${stagingPrefix}/account/feedback`,
		index: `${stagingPrefix}/account`,
		settings: `${stagingPrefix}/account/settings`,
	},
	new_board: `${stagingPrefix}/board`,
	board: (id: string) => `${stagingPrefix}/board/${id}`,
	confirm: `${stagingPrefix}/confirm`,
	demo: `${stagingPrefix}/demo`,
	educators: `${stagingPrefix}/educators`,
	form: `${stagingPrefix}/form`,
	forgot_password: `${stagingPrefix}/forgot_password`,
	login: `${stagingPrefix}/login`,
	logout: `${stagingPrefix}/logout`,
	signup: `${stagingPrefix}/signup`,
}

if (stagingPrefix.startsWith("http")) {
	// assume in this case that it's a redirect to the old app on express
	// handle some special routes that don't map directly to the new routes
	staging.account.boards = `${stagingPrefix}/boards`
	staging.account.contacts = `${stagingPrefix}/contacts`
	staging.account.feedback = `${stagingPrefix}/beta`
	staging.account.index = `${stagingPrefix}/boards`
	staging.account.settings = `${stagingPrefix}/settings`;
	staging.new_board = `${stagingPrefix}/board`;
	staging.demo = `${stagingPrefix}/board`;
}

export default {
	...staging,

	// non-staging
	contact: "/contact",
	index: "/",
	pricing: "/pricing",
	form: "/form",
	privacy_policy: "/privacy_policy",
	terms_of_service: "/terms_of_service",

	// todo (how to handle?)
	faq: "/faq",
	auth_google: "/auth/google",
}



////////////////////////////////////////////////////////////////////////
// mock Link and navigate() for urls that aren't necessarily internal //
////////////////////////////////////////////////////////////////////////

import React from "react"
import { Link as GatsbyLink, navigate as gatsbyNavigate } from "gatsby"

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
export const Link = (props) => {
	const { children, to, activeClassName, partiallyActive, ...other } = props;
  // This assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  )
}

export const navigate = (to, options: {replace?: boolean} = {}) => {
  // This assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
	const internal = /^\/(?!\/)/.test(to);
	if (internal) {
		gatsbyNavigate(to, options);
	} else {
		if (options.replace) {
			window.location.href = to;
		} else {
			window.location.replace(to);
		}
	}
}
