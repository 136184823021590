
import { send, ApiResult } from "../lib/send"
import { apiBaseUrl } from "../environment"

const accountApiPath = `${apiBaseUrl}/account`;
const accountApiRoutes = {
	add_contact: { url: accountApiPath, method: "post", func: 'add_contact' },
	get_contacts: { url: accountApiPath, method: "post", func: 'get_contacts' },
	remove_contact: { url: accountApiPath, method: "post", func: 'remove_contact' },
	update_contact: { url: accountApiPath, method: "post", func: 'update_contact' },
}

export type ContactInfo = {
	id: string,
	name: string,
	email: string,
}

export async function get_contacts(userToken: string): Promise<ApiResult<ContactInfo[]>> {
	return await send({ userToken }, accountApiRoutes.get_contacts);
}

export async function add_contact(userToken: string, name: string, email: string, sendInvite: boolean): Promise<ApiResult<ContactInfo>> {
	// TODO handle sendInvite
	return await send({ userToken, name, email }, accountApiRoutes.add_contact);
}

export async function update_contact(userToken: string, contact: ContactInfo): Promise<ApiResult<ContactInfo>> {
	return await send({ userToken, contact }, accountApiRoutes.update_contact);
}

export async function remove_contact(userToken: string, contact: ContactInfo): Promise<ApiResult<{}>> {
	return await send({ userToken, contactId: contact.id }, accountApiRoutes.remove_contact);
}
