
import User from "./lib/user"
import { send, ApiResult } from "./lib/send"
import session from "./lib/session"
import { navigate } from "./routes" // "gatsby"
import { apiBaseUrl } from "./environment"
import routes from "./routes"

const authApiPath = `${apiBaseUrl}/auth`;
const authApiRoutes = {
	signup: { url: authApiPath, method: "post", func: 'signup' },
	resend_confirmation_email: { url: authApiPath, method: "post", func: "resend_confirmation_email"},
	confirm: { url: authApiPath, method: "post", func: 'confirm' },
	login_with_password: { url: authApiPath, method: "post", func: 'login_with_password' },
	request_token: { url: authApiPath, method: "post", func: 'request_token' },
	reset_password_with_token: { url: authApiPath, method: "post", func: 'reset_password_with_token' },
}

export async function signup(formdata): Promise<ApiResult<{}>> {
	if (session.user !== null) {
		return { success: false, reason: "You are already logged in. You must log out before creating a new account." };
	}
	const response = await send(formdata, authApiRoutes.signup);
	if (response.success) {
		session.newUserToken(response.result.token);
	}
	return response;
}

export async function resend_confirmation_email(email): Promise<ApiResult<{}>> {
	return send({email}, authApiRoutes.resend_confirmation_email);
}

export async function confirm(email, key) {
	const response = await send({email, key}, authApiRoutes.confirm);
	if (response.success && session.user && session.user.email===email) {
		session.newUserToken(response.result.token);
	}
	return response;
}

export async function login_with_password(formdata) {
	if (session.user !== null) {
		return { success: false, reason: "You are already logged in. You must log out before logging in." };
	}
	const response = await send(formdata, authApiRoutes.login_with_password);
	if (response.success) {
		session.newUserToken(response.result.token, formdata.stay_logged_in==="on");
	}
	return response;
}

export async function request_token(email) {
	return send({email}, authApiRoutes.request_token);
}

export async function reset_password_with_token(formdata) {
	const response = await send(formdata, authApiRoutes.reset_password_with_token);
	if (response.success) {
		session.newUserToken(response.result.token);
	}
	return response;
}

export async function logout() {
	session.logout();
}

export function getSession() {
	return session;
}

export function getConfirmedUserOtherwiseNavigate(): User|null {
	if (session.user) {
		if (session.user.confirmed) {
			return session.user;
		}
		navigate(routes.confirm, { replace: true });
		return null;
	}
	navigate(routes.login, { replace: true });
	return null;
}